import React, { FC } from 'react';
import SEO from 'components/seo';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import Hero from 'components/hero';
import SignupForm from 'containers/signupForm/sign-up';

const TechAndTrustWhitepaperSignup: FC<{ location: string }> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Sign up Product Waitlist" />
      <Header />
      <main className="site-wrapper-reveal">
        <Hero title={'Product Waitlist'} hero="trust" />
        <SignupForm
          title={'Sign up for our Product Waitlist'}
          formGroupId={'group[68204][2]'}
          checkboxCopy="By joining our product waitlist you are signing up for the ATB Ventures newsletter."
        />
      </main>
      <Footer />
    </Layout>
  );
};

export default TechAndTrustWhitepaperSignup;
